<template>
    <div class="content-wrapper">
        <rqValidationSummary :dataSource="validationErrors" />
        <rq-page-section title="Title Companies" header-size="lg" borderless>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid_1099"
            automation_id="dataGrid_1099"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="1099-title-company-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            target-inactive-column="isInactive"
            :allow-activate="allowActivate"
            :show-inactive-column="showInactiveColumn"
            @generateReport="onGenerateReport"
            @createIRSFile="onCreateIRSFile"
            @sendIRSFile="onSendIRSFile"
            @editorPreparing="onEditorPreparing"
            @reset="onGridReset"
            focus-after-insert="first-row"
            rq-filters
            integrated-search
            show-include-inactive>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { mapState, mapGetters } from "vuex";
    import { filing1099ActionEnum } from "../models";
    import File1099Action from "./File1099Action";
    import { DxFilterBuilder } from "@/shared/utilities";
    import LookupNames from "@/store/lookupNames";
    import { REFRESH_LOOKUP } from "@/store/actions";

    export default {
        name: 'FilingReports',

        props: { },

        components: { },

        data() {
            return {
                itemKey: "companyID",
                itemTypeName: "Company",
                selectionActions: [],
                validationErrors:[],
                searchTerm: "",
                minSearchTermLength: 3,
                showInactiveColumn: false,
                showActive: false,
                showInactive: false,
                allowActivate: false
            }
        },

        watch: {
            showInactiveColumn(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.invokeGridMethod("columnOption", "isInactive", "visible", newValue);
                let hasInactiveFilter = this.invokeGridComponentMethod("hasFilter", "isInactive");
                if(hasInactiveFilter)
                    this.invokeGridComponentMethod("updateFilter", "isInactive", null);
                else
                    this.invokeGridMethod("refresh");
            },
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                loans: state => state.orders.loans || [],
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                userId: state => state.authentication.session.userId
            }),
            gridInstance () { return this.$refs.dataGrid_1099.gridInstance; },
            hasError() { return this.validationErrors.length > 0; }
        },

        beforeCreate() { },

        created() {
            const self = this;
            self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: LookupNames.STAFF });
            self.selectionActions = self.readOnly ? [] : [
                { name: "Generate Report", text: "Generate Report", eventName: "generateReport", allowMultiSelection: true, tooltip: `Generate Report` },
                { name: "Create IRS File", text: "Create IRS File", eventName: "createIRSFile", allowMultiSelection: true, tooltip: `Create IRS File` },
                { name: "Send File to Ramquest", text: "Send File to Ramquest", eventName: "sendIRSFile", allowMultiSelection: true, tooltip: `Send File to Ramquest` }
            ];
            self.initGridConfig();
        },

        beforeMount() { },

        mounted() { },

        beforeUpdate() { },

        updated() { },

        beforeUnmount() { },

        unmounted() { },

        methods: {
            onGridReset(e) {
                this.showInactiveColumn = false;
                this.$emit("reset");
            },
            initGridConfig() {
                const self = this;

                self.gridConfig = {
                    sorting: { mode: 'single' },
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.lookupHelpers.getRegions(),
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            }
                        },
                        { dataField: "companyID", caption:"Company ID" },
                        {
                            dataField: "name",
                            caption:"Name",
                        },
                        { dataField: "city", caption: "City", dataType: "string" },
                        {
                            dataField: "state",
                            caption: "State",
                            dataType: "string",
                        },
                        { dataField: "phone", dataType: "string" },
                        { dataField: "isInactive"
                          , caption: "Inactive"
                          , dataType: "boolean"
                          , cellTemplate: DxGridUtils.boolCellTemplate
                          , visible: false
                        }
                    ],
                }

                self.initGridDataSource();
            },
            initGridDataSource() {
                const self = this;
                let api = self.$api.File1099ReportApi;
                self.gridDataSource = {
                    key: self.itemKey,
                    load(){
                        return self.fetchTitleCompanyData();
                    },
                };
            },
            search() {
                const self = this;
                self.clearCompanyGridSelections();
                self.refreshCompanyGrids();
            },
            clearCompanyGridSelections() {
                this.gridInstance.deselectAll();
            },
            refreshCompanyGrids() {
                this.gridInstance.refresh();
            },
            fetchData() {
                const self = this;
                return self.$api.File1099ReportApi.getByUserId(self.userId);
            },
            fetchTitleCompanyData(loadOptions) {
                 const self = this;
                let request = new SearchRequest({
                    searchTerm: self.searchTerm,
                    parameters: { },
                    pagingEnabled: false
                });
                let apiPromise = self.$api.File1099ReportApi.searchTitleCompanies(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        return {
                            data: response.results,
                            totalCount: response.totalRecords
                        };
                    }).catch(err => {
                        console.error(err);
                        return { data: [], totalCount: 0 };
                    });
            },
            onEditorPreparing(e) {
                const self = this;
                if(e.type === "selection" || e.row.rowType !== "data") return;
                e.editorOptions.disabled = true;
            },
            onGenerateReport(e) {
                if(!e || !e.data) return;
                const self = this;
                let okHandler = function (args) {
                    let reportOptions = {
                        reportName: args?.component?.reportParameters?.p_Option1099 == 1 ? "1099 Missing Information": "All 1099 Files",
                        reportPath: "System Reports/File Specific/1099 Missing Information",
                        parameters: args.component.reportParameters,
                        immediate: true
                    };

                    _.invoke(self, "$rq.showReport", reportOptions.reportPath, reportOptions);
                    return true;
                }
                let selectedCompanies = _.invoke(self, "$refs.dataGrid_1099.gridInstance.getSelectedRowsData");
                self.$dialog.open({
                    title: "Generate Report For Proofing",
                    width: "1200",
                    adaptive: true,
                    selectable: false,
                    component: File1099Action,
                    props: {
                        titleCompanies: selectedCompanies,
                        action: filing1099ActionEnum.GenerateReport
                    },
                    okTitle: "Generate Report",
                    onOk: okHandler
                });
            },
            onCreateIRSFile(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let onOK = function (args) {
                    if (args.component.fileComplete) {
                        self.search();
                        return true;
                    }
                    let result = args.component.processCreateIRSFile();
                    return result;
                }
                let onCancel = function (args) {
                    self.search();
                    return true;
                }
                let selectedCompanies = _.invoke(self, "$refs.dataGrid_1099.gridInstance.getSelectedRowsData");
                self.$dialog.open({
                    title: "Create IRS File",
                    width: "1400",
                    adaptive: true,
                    selectable: false,
                    component: File1099Action,
                    props: {
                        titleCompanies: selectedCompanies,
                        action: filing1099ActionEnum.CreateIRSFile
                    },
                    okTitle: "Create File",
                    onOk: onOK,
                    onCancel: onCancel
                });
            },
            onSendIRSFile(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    let result = args.component.processSendFile();
                    return result;
                }
                let selectedCompanies = _.invoke(self, "$refs.dataGrid_1099.gridInstance.getSelectedRowsData");
                self.$dialog.open({
                    title: "Send File To RamQuest",
                    width: "1400",
                    adaptive: true,
                    selectable: false,
                    component: File1099Action,
                    props: {
                        titleCompanies: selectedCompanies,
                        action: filing1099ActionEnum.SendIRSFile
                    },
                    okTitle: "Send File",
                    onOk: okHandler
                });
            },
            invokeGridMethod(method, ...params) {
                return _.invoke(this, `gridInstance.${method}`, ...params);
            },
            invokeGridComponentMethod(method, ...params) {
                return _.invoke(this, `$refs.dataGrid.${method}`, ...params);
            }
        }
    };
</script>